import React from 'react';
import './Work.css';
import Container from '@mui/material/Container';

function Work() {
  return (
    <>
      <Container maxWidth="xl">
        hi 
      </Container>
    </>
  );
}


export default Work;
